import React, { useState } from 'react';
import screen from '../img/screen.png';
import logo from '../img/WebLogo.svg';
import { useNavigate } from 'react-router-dom';
import { BASE_URL_API } from '../environment';
import axios from 'axios';
import Swal from 'sweetalert2';
function OrgLog() {
    const Navigate =useNavigate();
    const [mobileno, setMobileNO] = useState("");
    const [otp, setOtp] = useState("");
    const userid = "kalingaterrier";
    const password = "120TA";
    const orglogin = () => {
      Navigate('/orgdash');  
    }
    //getting otp function
    const getotp = () =>{
      axios
      .post(BASE_URL_API + "customers/login", {
        mobile: mobileno,
      })
      .then((res) => {
        console.log(res.data);
        console.log(res.data.length);
        Swal.fire({
          position: "center",
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    }

    //login function for business customer
    const login = () => {
      if (mobileno.trim() === userid && otp.trim() === password) {
        // Set the name and id in localStorage
        localStorage.setItem("customer_name", "The 120 Infantry Battalion (Territorial Army)");
        localStorage.setItem("customer_id", "65d30e9f4d885c853fbb2b06");
  
        // Show success message
        Swal.fire({
          icon: "success",
          title: "Login successfully",
        }).then(() => {
          Navigate('/businesscustomer');
        });
      } else {
        // Show error message
        Swal.fire({
          icon: "error",
          title: "Invalid UserID or Password",
        });
      }
    };

    //verify otp function
    const verifyOtp = () => {
      axios
        .post(BASE_URL_API + "customers/verifyOTP", {
          mobile: mobileno,
          OTP: otp,
        })
        .then((res) => {
          console.log(res.data);
          const message = res.data.message;
    
          if (message === "Wrong OTP enter.") {
            setOtp("");
            Swal.fire({
              icon: "error",
              title: message,
            });
          } else if (message === "Customer doesn't exists") {
            Swal.fire({
              icon: "error",
              title: message,
            });
          } else if (message === "Customer already exists") {
            if (res.data.customerDetails && Object.keys(res.data.customerDetails).length > 0) {
              const customerId = res.data.customerDetails._id;
              localStorage.setItem("customer_id", customerId);
              localStorage.setItem("customer_name", res.data.customerDetails.name);
              Swal.fire({
                icon: "success",
                title: "Login successfully",
              }).then(() => {
                Navigate('/businesscustomer');
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "An error occurred. Please try again.",
          });
        });
    };
  return (
    // login function
    <div className="container-fluid ">
    <div className="row d-flex justify-content-center align-items-center ">
      <div className="row g-0 " style={{ minHeight: "100vh" }}>
        <div
          className="col-md-6 col-lg-6 d-none d-md-block p-3 "
          style={{
            backgroundColor: "#FFFCDC",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <div  className="image-container d-flex justify-content-center align-items-center "
            style={{
              height: "100%",
              width: "100%",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <img src={screen}
              alt="login form"
              className="img-fluid"
              style={{
                position: "absolute",
                borderRadius:"30px"
              }}
            />
          </div>
        </div>

        <div className="col-md-6 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center ">
          <div className="col-md-5 col-sm-8 border-1">
            <form>
            <div className="text-center mb-2">
                <img
                  className="img-responsive img-circle img-thumbnail img-user"
                  src={logo}
                  alt="Logo"
                  style={{ width: "180px", border: "none" }}
                />
              </div>
            <div className="text-center mb-2">
                <p style={{ color: "#73be44" , fontSize:"20px", fontWeight:"bold"}}>Welcome to Recycle Pay Business</p>
                <p style={{ color: "#000" , fontSize:"12px", fontWeight:"bold"}}></p>
              </div>
              

              <div className="form-outline mb-3">
                <label
                  className="form-label"
                  htmlFor="form2Example17"
                  style={{ fontSize: "13px", color: "#73be44", fontWeight:"bold" }}
                >
                  Enter User ID
                </label>
                <input
                  type="text" 
                  // pattern="[0-9]{10}" 
                  placeholder="User ID"
                  id="form2Example17"
                  className="form-control form-control-sm"
                  style={{ borderColor: "#73be44" }}
                  value={mobileno}
                  onChange={(e)=>{setMobileNO(e.target.value)}}
                  // maxLength={10}
                />
              </div>
              {/* <div className='d-flex mb-2 justify-content-end'>
                <button
                  className="btn btn-sm"
                  style={{ backgroundColor: "#73be44", color: "white" }}
                  type="button"
                  onClick={getotp}
                >
                  Get OTP
                </button>
              </div> */}
              <div className="form-outline mb-3">
                <label
                  className="form-label"
                  htmlFor="form2Example27"
                  style={{ fontSize: "13px", color: "#73be44", fontWeight: "bold" }}
                >
                  Enter Password
                </label>
                <input
                  type="text" 
                  // pattern="[0-9]{10}" 
                  placeholder="Password"
                  id="form2Example17"
                  className="form-control form-control-sm"
                  style={{ borderColor: "#73be44" }}
                  // maxLength={4}
                  value={otp}
                  onChange={(e) => {setOtp(e.target.value)}}
                />
              </div>
              <div className="d-grid pt-4 mb-4">
                <button
                  className="btn btn-sm btn-block"
                  style={{ backgroundColor: "#73be44", color: "white" }}
                  type="button"  onClick={login}
                  // onClick={verifyOtp}
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default OrgLog
